import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { topTabsData } from "./sidebarData";
import { RootState } from "../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Logo } from "../../Assets/Images/SVG/greenLogoIcon.svg";
import { PowerSettingsNew, Close } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SignOut } from "../../Redux/Features/Auth-Slice";
import { clearOrganizationList } from "../../Redux/Features/dashboard-slice";
import "./sidebar.scss";

interface SingleTabProps {
  label: string;
  Icon: any;
  selected: string;
  path: string;
  setSelected: (e: string) => void;
}

const SingleTab = ({ label, path, setSelected, Icon }: SingleTabProps) => {
  const location = useLocation();
  return (
    <Box className="menuItem">
      <span
        className="menuItem__link"
        onClick={() => setSelected(label)}
        style={{
          background:
            location.pathname === path
              ? "rgba(255, 255, 255, 0.29)"
              : "transparent",
        }}
      >
        <Icon />
        <span>
          <Link to={path} className="sidebarLink">
            {label}
          </Link>
        </span>
      </span>
    </Box>
  );
};

interface ChildProps {
  openMenu: boolean;
  menuBtnEvent: any;
}

const Sidebar: React.FC<ChildProps> = ({
  openMenu,
  menuBtnEvent,
}: ChildProps) => {
  const [selected, setSelected] = useState("");
  const userData = useSelector((state: RootState) => state.Auth.user);
  const isLogin = useSelector((state: RootState) => state.Auth.isLogin);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = (e: any) => {
    e.preventDefault();
    dispatch(SignOut());
  };

  // Handle navigation in effect
  useEffect(() => {
    if (!isLogin) {
      dispatch(clearOrganizationList());
      navigate("/login");
    }
  }, [isLogin, dispatch, navigate]); // Only re-run if these dependencies change

  return (
    <>
      <Box
        className={openMenu === false ? "sidebar" : "sidebar sidebar--active"}
      >
        <Box>
          <Box
            className="mobileMenuBtn"
            onClick={() => menuBtnEvent()}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            style={{ cursor: "pointer" }}
          >
            <Close />
          </Box>
          <Box className="sidebar__logo">
            <Logo />
          </Box>
          <Box className="sidebar__menu">
            {topTabsData?.map((el: any) => {
              return (
                <SingleTab
                  label={el.name}
                  Icon={el.icon}
                  path={el.path}
                  selected={selected}
                  setSelected={setSelected}
                  key={el.name}
                />
              );
            })}
          </Box>
        </Box>
        <Box width="100%" borderRadius="5px">
          <Box
            className="userBlock"
            display={"flex"}
            alignItems={"center"}
            gap={"15px"}
            padding={"10px"}
            bgcolor={"#0000004A"}
          >
            <Box className="userBlock__image">
              <Button
                onClick={(e) => handleLogout(e)}
                sx={{
                  minWidth: "0px",
                  padding: "0px",
                }}
              >
                <PowerSettingsNew />
              </Button>
            </Box>
            <Box className="userBlock__info">
              <Typography className="userBlock__name">
                {userData?.username}
              </Typography>
              <Button
                className="userBlock__profile"
                sx={{
                  color: "#42DB89",
                  textTransform: "none",
                  minWidth: "0px",
                  padding: "0px",
                }}
                onClick={(e) => handleLogout(e)}
              >
                Logga ut
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Sidebar;
