// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  background: #06b4c8 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border: none !important;
}
.btn--green {
  background: #42db89 !important;
}
.btn--orange {
  background: #f89e6a !important;
}
.btn--small {
  font-weight: 600 !important;
  line-height: 20px !important;
  border-radius: 5px !important;
}

.tabContainer::after {
  width: 0.25rem;
  height: 100%;
  content: "";
  display: block;
  position: absolute;
  left: -30px;
  top: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,2BAAA;EACA,2BAAA;EACA,2BAAA;EACA,0BAAA;EACA,4BAAA;EACA,sBAAA;EACA,qCAAA;EACA,uBAAA;AACF;AACE;EACE,8BAAA;AACJ;AAEE;EACE,8BAAA;AAAJ;AAEE;EACE,2BAAA;EACA,4BAAA;EACA,6BAAA;AAAJ;;AAIA;EACE,cAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,QAAA;AADF","sourcesContent":[".btn {\n  background: #06b4c8 !important;\n  border-radius: 0 !important;\n  box-shadow: none !important;\n  font-weight: 800 !important;\n  font-size: 14px !important;\n  line-height: 24px !important;\n  color: #fff !important;\n  text-transform: capitalize !important;\n  border: none !important;\n\n  &--green {\n    background: #42db89 !important;\n  }\n\n  &--orange {\n    background: #f89e6a !important;\n  }\n  &--small {\n    font-weight: 600 !important;\n    line-height: 20px !important;\n    border-radius: 5px !important;\n  }\n}\n\n.tabContainer::after {\n  width: 0.25rem;\n  height: 100%;\n  content: \"\";\n  display: block;\n  position: absolute;\n  left: -30px;\n  top: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
