import {
  Box,
  TextField,
  Typography,
} from "@mui/material";

const GetOrganizationForm = ({registerData, onChange, emailError}:any) => {
  return (
    <>
      <form>
        <Box className="formGroup">
          <TextField
            type="email"
            name="email"
            value={registerData?.email}
            fullWidth
            label="Användarnamn"
            onChange={(e) => onChange(e)}
            focused
          />
        </Box>
        <Typography className="errorText">
          {emailError}
        </Typography>
      </form>
    </>
  );
};

export default GetOrganizationForm;
