import "./loader.scss";

const Loader = () => {
  return (
    <svg
      width="28"
      className="a-loader"
      height="21"
      viewBox="0 0 28 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3227 14.1879L13.5307 15.4485L15.7388 14.1879L24.8526 8.9849L27.0606 7.72426L24.8526 6.46363L15.7388 1.26064L13.5307 0L11.3227 1.26064L2.20804 6.46363L0 7.72426L2.20804 8.9849L4.55691 10.3262L0 12.9273L13.5307 20.6515L18.0876 18.0455L15.8088 16.7426L13.5307 18.0455L4.55361 12.9232L6.83248 11.6202L11.3227 14.1879ZM6.76495 8.9849L4.55691 7.72426L6.76495 6.46363L13.5307 2.60597L20.2957 6.46851L22.5037 7.72915L20.2957 8.98979L13.5307 12.8515L9.11382 10.3262L13.5307 7.80163L15.672 9.02318L17.9501 7.72019L13.534 5.19566L6.83577 9.02481L6.76495 8.9849Z"
        fill="#42DB89"
      />
    </svg>
  );
};

export default Loader;
