import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./Redux/Store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./Assets/Global-Style/index.css";
import { BrowserRouter } from "react-router-dom";
import "./i18n/i18n.config";
import 'mapbox-gl/dist/mapbox-gl.css';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';

const container = document.getElementById("root")!;
const root = createRoot(container);

// Create a custom theme.
const theme = createTheme({
  typography: {
    fontFamily: 'Red Hat Text, sans-serif', // Specify your preferred font here.
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);


reportWebVitals();
