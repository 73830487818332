import { Box, Button, Link } from "@mui/material";
import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import moment from "moment";
import { handleBgColorMarker, swedishIt } from "../../Utils/Utils";
import Map from "../Map/Map";
// import Loader from "../Loader/Loader";
import StreetViewMap from "../Map/StreetViewMap";
import MapModel from "../Map/MapModel";
// import { RootState } from "../../Redux/Store";
import "./leaseinfo.scss";

interface LesserInfoProps {
  caseId: number | null;
  WorkOrderList: any;
}

const LeaseInfo = ({ caseId, WorkOrderList }: LesserInfoProps) => {
  const [viewport, setViewport] = useState({
    latitude: 59.334591,
    longitude: 18.06324,
    zoom: 8,
    width: "100%",
    height: "45vh",
    margin: "auto",
  });

  const [polygonFromCaseLocation, setPolygonFromCaseLocation] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [markerCenter, setMarkerCenter] = useState({
    latitude: 0,
    longitude: 0,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [networkCoords, setNetworkCoords] = useState([]);
  const [networkImages, setNetworkImages] = useState([]);
  const [open, setOpen] = useState<boolean>(false);
  const [mapView, setMapView] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  useEffect(() => {
    const newViewport = {
      ...viewport,
      latitude: WorkOrderList?.CAddress?.[0]?.[0] === undefined ? 59.334591 : WorkOrderList?.CAddress?.[0]?.[0],
      longitude: WorkOrderList?.CAddress?.[0]?.[1] === undefined ? 18.06324 : WorkOrderList?.CAddress?.[0]?.[1],

      zoom: 17,
    };
    setViewport(newViewport);

    setPolygonFromCaseLocation(
      WorkOrderList?.Geometry4326
    );
    setMarkerCenter({
      latitude: WorkOrderList?.CAddress?.[0]?.[0],
      longitude: WorkOrderList?.CAddress?.[0]?.[1],
    });
    if (WorkOrderList && WorkOrderList?.network_coords) {
      setNetworkCoords(WorkOrderList?.network_coords);
    }
    if (WorkOrderList && WorkOrderList?.Cable_Data) {
      setNetworkImages(WorkOrderList?.Cable_Data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WorkOrderList]);

  return (
    <>
      <Box>
        <div className="tabmain">
          <span className="infoLabel"> # {WorkOrderList?.WOCaseId}</span>
        </div>
        <div className="lesserInfo">
          <span className="infoLabel">Plats</span>
          <span className="infoValue">
            {WorkOrderList && WorkOrderList.Address
              ? [WorkOrderList.Address?.StreetNameAndNumber]
                .filter(Boolean)
                .join(', ')
              : "-" // Default text or handling if address parts are missing
            }
          </span>
        </div>
        <div className="lesserInfo">
          <span className="infoLabel">Startdatum</span>
          <span className="infoValue">
            {moment(WorkOrderList?.start_date).format("MMM DD YYYY ")}
          </span>
        </div>
        <div className="lesserInfo">
          <span className="infoLabel">Antal område</span>
          <span className="infoValue">
            {WorkOrderList?.number_of_sites}
          </span>
        </div>
  
        <Box className="mapViewBox">
          <>
            {!mapView ? (
              <Map
                viewport={viewport}
                setViewport={setViewport}
                polygonFromCaseLocation={polygonFromCaseLocation}
                networkCoords={networkCoords}
                WorkOrderList={WorkOrderList}
                networkImages={networkImages}
                setOpen={setOpen}
                mapView={mapView}
                setMapView={setMapView}
                handleOpen={handleOpen}
                open={open}
              />
            ) : (
              <StreetViewMap
                viewport={viewport}
                setViewport={setViewport}
                polygonFromCaseLocation={polygonFromCaseLocation}
                networkCoords={networkCoords}
                WorkOrderList={WorkOrderList}
                networkImages={networkImages}
                setOpen={setOpen}
                mapView={mapView}
                setMapView={setMapView}
                handleOpen={handleOpen}
                open={open}
              />
            )}
          </>
        </Box>
      </Box>
  
      {open && (
        <MapModel
          viewport={viewport}
          setViewport={setViewport}
          polygonFromCaseLocation={polygonFromCaseLocation}
          networkCoords={networkCoords}
          WorkOrderList={WorkOrderList}
          networkImages={networkImages}
          setOpen={setOpen}
          handleClose={handleClose}
          open={open}
        />
      )}
    </>
  );
};

export default LeaseInfo;
