import { MessageType } from "../../Types";
import "./alert.scss";
/**
 * Notification shown at the top of the website.
 */
export function Alert({
  _id,
  message,
  startdate,
  enddate,
  title,
  severity,
}: Readonly<MessageType>) {
  const dismissAction = (_id: string) => {
    const messages = localStorage.getItem("messages");
    if (messages) {
      const parsedMessages: Array<string> = JSON.parse(messages);
      parsedMessages.push(_id);
      localStorage.setItem("messages", JSON.stringify(parsedMessages));
      window.dispatchEvent(new StorageEvent("storage", { key: "messages" }));
      return;
    }
    localStorage.setItem("messages", JSON.stringify([_id]));
    window.dispatchEvent(new StorageEvent("storage", { key: "messages" }));
  };
  return (
    <div
      role="alert"
      className="alertBox"
      style={{ background: color(severity)[0] }}
    >
      <div className="container">
        <div className="content" style={{ color: color(severity)[1] }}>
          {title && <p className="title">{title}</p>}
          <p>{message}</p>
        </div>

        <button
          onClick={() => dismissAction(_id)}
          style={{
            background: color(severity)[2],
            //@ts-ignore
            "--hover-color": color(severity)[3],
          }}
          className={"messageButton"}
        >
          Stäng
        </button>
      </div>
    </div>
  );
}

/**
 * Returns array of class names (background / text color) based on given type.
 */
function color(type: string) {
  switch (type) {
    case "warning":
      return [
        "#ffedd5", // bg-orange-100
        "#c2410c", // text-orange-700
        "#c2410c", // bg-orange-700
        "#9a3412", // hover:bg-orange-800
      ];
    case "danger":
      return [
        "#ffe4e6", // bg-rose-100
        "#be123c", // text-rose-700
        "#be123c", // bg-rose-700
        "#9f1239", // hover:bg-rose-800
      ];
    case "success":
    case "info":
      return [
        "#dcfce7", // bg-green-100
        "#15803d", // text-green-700
        "#15803d", // bg-green-700
        "#166534", // hover:bg-green-800
      ];
    default:
      return [
        "#e0f2fe", // bg-sky-100
        "#0369a1", // text-sky-700
        "#0369a1", // bg-sky-700
        "#075985", // hover:bg-sky-800
      ];
  }
}
