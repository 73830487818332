import React, { useMemo, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../Assets/Images/SVG/eningoDarkLogo.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../Login/login.scss";
import { checkIsEmail } from "../../Utils/Utils";
import { useDispatch } from "react-redux";
import { UserForgotPassword } from "../../Redux/Features/Auth-Slice";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [isEmailError, setIsEmailError] = useState("");
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    email: "",
  });
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (e.target.name === "email") setIsEmailError("");
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const validation = (userData: any) => {
    let status = true;
    if (userData?.email?.length === 0) {
      setIsEmailError("Ange e-postadress ");
      status = false;
    } else {
      if (!checkIsEmail(userData?.email)) {
        setIsEmailError("Fel e-postadress ");
        status = false;
      }
    }
    return status;
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validation(userData)) {
      dispatch(
        UserForgotPassword({
          email: userData.email,
        })
      );
    }
  };
  return (
    <React.Fragment>
      <div className="formTemplate formTemplate">
        <div className="formTemplate__container">
          <div className="formTemplate__logo">
            <Logo />
          </div>
          <div
            className="formCard formCard--green"
            style={{ minHeight: "365px" }}
          >
            <Typography component="h1" variant="h5" className="formCard__title">
              {t("labels.forgotPassword")}
            </Typography>
            <form>
              <Box className="formGroup">
                <TextField
                  type="email"
                  fullWidth
                  label={t("labels.email")}
                  name="email"
                  value={userData?.email}
                  onChange={(e) => handleChange(e)}
                  focused
                />
                <Typography className="errorText">{isEmailError}</Typography>
              </Box>
              {/* <Box className="formGroup">
                <TextField
                  type="password"
                  fullWidth
                  label={t("labels.password")}
                  inputRef={password}
                  focused
                />
              </Box>
              <Box className="formGroup">
                <TextField
                  type="password"
                  fullWidth
                  inputRef={newPassword}
                  label={t("labels.newPassword")}
                  focused
                />
              </Box> */}
              <Box className="formCard__btn">
                <Button
                  variant="contained"
                  color="primary"
                  className="btn"
                  onClick={(e) => handleSubmit(e)}
                  disabled={userData.email === ""}
                >
                  {t("labels.send")}
                </Button>
              </Box>
            </form>
            <p className="contentLink" style={{ textAlign: "center"}}>
              {/* {t("labels.backtoLogin")} */}
              <Link to="/login" style={{ marginRight: "5px" , color: "#117A65", textDecoration: "none"}}>
                {" "}
                {t("labels.login")}
              </Link>
              {t("istället")}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
