import { useEffect, useState } from "react";
import { Button, Box, Step, StepLabel, Stepper } from "@mui/material";
import GetOrganizationForm from "./getOrganizationForm";
import { useDispatch, useSelector } from "react-redux";
import {
  GetOrganization,
  SignUp,
  clearOrganization,
} from "../../Redux/Features/Auth-Slice";
import SecondStep from "./SecondStep";
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkIsEmail } from "../../Utils/Utils";

const steps = ["Steg 1", "Klar!"];

const HorizontalLinearStepper = () => {
  const navigation = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();

  const organization = useSelector((state: any) => state.Auth.organization);
  const organizationUser = useSelector(
    (state: any) => state.Auth.organizationUser
  );
  const organizationUserName = useSelector(
    (state: any) => state.Auth.organizationUserName
  );
  const isRegister = useSelector((state: any) => state.Auth.isRegister);
  const [emailError, setIsEmailError] = useState<string>("");

  const [registerData, setRegisterData] = useState({
    email: "",
    userName: "",
    OrganizationNumber: "",
    password: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "email") setIsEmailError("");
    setRegisterData({
      ...registerData,
      [name]: value,
    });
  };

  const _renderStepContent = (step: any) => {
    switch (step) {
      case 0:
        return (
          <GetOrganizationForm
            registerData={registerData}
            onChange={handleChange}
            emailError={emailError}
          />
        );
      case 1:
        return (
          <SecondStep
            registerData={registerData}
            onChange={handleChange}
            organization={organization}
            organizationUser={organizationUser}
            organizationUserName={organizationUserName}
          />
        );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (registerData?.email?.length === 0) {
      return setIsEmailError("Användarnamn krävs");
    }
    if (!checkIsEmail(registerData?.email))
      return setIsEmailError("E-post är inte giltig");

    if (
      organization.length === 0 &&
      (organizationUser === null || organizationUser === undefined) &&
      activeStep === 0
    ) {
      dispatch(GetOrganization({ email: registerData?.email }));
    } else {
      if (organizationUserName === "private") {
        /// private api call here
        let userReg = {
          email: registerData?.email,
          username: organizationUser,
          password: registerData?.password,
        };
        dispatch(SignUp(userReg));
      } else {
        /// public user api call here
        let registerUser = {
          email: registerData?.email,
          OrganizationNumber: registerData?.OrganizationNumber,
          username: organizationUser,
          password: registerData?.password,
        };
        dispatch(SignUp(registerUser));
      }
    }
  };

  useEffect(() => {
    if (
      organization.length !== 0 ||
      (organizationUserName === "private" && organizationUser !== undefined)
    ) {
      setActiveStep(activeStep + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, organizationUser]);

  if (isRegister) navigation("/login");


  const [searchParams] = useSearchParams();
  const searchEmail = searchParams.get('email');

  useEffect(() => {
    dispatch(clearOrganization());
    if (searchEmail !== null) {
      setRegisterData({
        ...registerData,
        email: searchEmail,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <Box className="registerStepper">
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div>
          {_renderStepContent(activeStep)}
          <Box className="registerStepper__btns">
            <Button
              disabled={activeStep === 0}
              onClick={() => {
                dispatch(clearOrganization());
                setActiveStep(activeStep - 1);
              }}
              variant="contained"
              color="primary"
              className="btn"
            >
              Tillbaka
            </Button>

            <Button
              type="submit"
              onClick={(e) => handleSubmit(e)}
              variant="contained"
              color="primary"
              className="btn btn--green"
            >
              {activeStep === 0 ? "Nästa" : "Skapa konto"}
            </Button>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default HorizontalLinearStepper;
