import { Box } from "@mui/material";
import { Menu } from "@mui/icons-material";
import {  useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";
import "./header.scss";
import { useEffect } from "react";

interface ChildProps {
  menuEvent: () => void;
  openMenu: boolean;
  showLogout:boolean
}

const Header: React.FC<ChildProps> = (props) => {
  const navigate = useNavigate();
  const isLogin = useSelector((state: any) => state.Auth.isLogin);
  
  useEffect(() => {
    if (!isLogin) { navigate("/login");}
  }, [isLogin, navigate]);

  return (
    <Box
      className="mainHeader"
      p={"20px 25px"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderBottom={"1px solid #C4C4C4"}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={"20px"}
        className="mainHeader__heading"
      >
        <Box
          onClick={() => props.menuEvent()}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          style={{ cursor: "pointer" }}
        >
          <Menu />
        </Box>
      </Box>
    </Box>
  );
};
export default Header;
