/* eslint-disable no-useless-escape */
export const checkIsEmail = (email: string): boolean => {
  const emailRegexp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
  return emailRegexp.test(email);
};

export const checkIsPassword = (password: string): boolean => {
  const passwordRegexp =
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,15}/;
  return passwordRegexp.test(password);
};

export const handleBgColorMarker = (status: string, WOtype:string) => {
  if (WOtype?.toLowerCase()?.includes("cable")) {
    if (status?.toLowerCase() === "unassigned") return "#FF7043";
    else if (status?.toLowerCase() === "assigned") return "#FF5349";
    else if (
      status?.toLowerCase() === "completed" ||
      status?.toLowerCase() === "done" ||
      status?.toLowerCase() === "complete"
    )
      return "#42DB89";
    
    else if (status?.toLowerCase()?.includes("issue")) 
      return "#FF6E7F"

  }
  else if (WOtype?.toLowerCase()?.includes("project")) {
    if (status?.toLowerCase() === "unassigned") return "#FF7043";
    if (status?.toLowerCase() === "assigned") return "#FF5349";
    else if (
      status?.toLowerCase() === "completed" ||
      status?.toLowerCase() === "done" ||
      status?.toLowerCase() === "complete"
    )
    return "#42DB89";
  } 
};

export const handleChangeLabel =(label:string)=>{
  if(label?.toLowerCase()=== "cable indication") return "Ledningsanvisning";
  else if(label?.toLowerCase() === "project") return "Projektering";
  else if(label?.toLowerCase() === "collaboration") return "Samordning";
  else if(label?.toLowerCase() === "emergency") return "Akutärenden";
  else if(label?.toLowerCase()=== "cancelled") return "Makulerat"
}
export const swedishIt = (status: string, WOtype: string) => {
  if (WOtype?.toLowerCase()?.includes("cable")) {
    if (status?.toLowerCase() === "unassigned") return "Väntar på utsättning";
    else if (status?.toLowerCase() === "assigned") return "Väntar på utsättning";
    else if (
      status?.toLowerCase() === "completed" ||
      status?.toLowerCase() === "done" ||
      status?.toLowerCase() === "complete"
    )
      return "Klar";
    
    else if (status?.toLowerCase()?.includes("issue")) 
      return "Avvikelse";
  }
  else if (WOtype?.toLowerCase()?.includes("project")) {
    if (status?.toLowerCase() === "unassigned") return "Ej Klar";
    if (status?.toLowerCase() === "assigned") return "Väntar på utsättning";

    else if (
      status?.toLowerCase() === "completed" ||
      status?.toLowerCase() === "done" ||
      status?.toLowerCase() === "complete"
    )
    return "Klar";
    } 
};

export const filtersData= {
  "Alla Ärenden":"all",
  "Tilldelade":"assigned",
  "Otilldelade":"unassigned",
  "Avvikelser":"issue",
  "Avslutade ärenden":"done"
};

export const rowsPerPageItem={
  "Sidstorlek":"Sidstorlek",
  "10":"10",
  "20":"20",
  "30":"30"
}
