import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MessageType } from "../Types";
import messageService from "../Service/message.service";

type MessageData = {
  messages: Array<MessageType>;
};

export const Ctx = createContext<MessageData | undefined>(undefined);

export const useMessageCtx = () => {
  const ctx = useContext(Ctx);
  if (ctx === undefined) {
    throw new Error("Context is undefined");
  }
  return ctx;
};

export const MessageDataContext = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [messages, setMessages] = useState<Array<MessageType>>([]);

  const getMessages = useCallback(async () => {
    if (!process.env.REACT_APP_MESSAGE_SERVER) return;
    try {
      const response = await messageService.get("/messages?platform=portal");
      if (response) setMessages(filterMessages(response.data.data));
    } catch (error) {
      return error;
    }
  }, []);

  const filterMessages = (messages: Array<MessageType>) => {
    const lMessages = localStorage.getItem("messages");
    if (lMessages) {
      const parsedMessages: Array<string> = JSON.parse(lMessages);
      return messages.filter((m) => !parsedMessages.includes(m._id));
    }
    return messages;
  };

  useEffect(() => {
    function checkStorage(e: StorageEvent) {
      if (e.key === "messages") {
        setMessages((prev) => filterMessages(prev));
      }
    }
    window.addEventListener("storage", (e) => checkStorage(e));
    return () => {
      window.removeEventListener("storage", checkStorage);
    };
  }, []);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  const value = useMemo(
    () => ({
      messages,
    }),
    [messages]
  );
  return <Ctx.Provider value={value}>{children}</Ctx.Provider>;
};
