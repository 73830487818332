import React from "react";
import { Box, Button, Tab, Tabs } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LeaseInfo from "./LeaseInfo";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TabContainProps {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  handleClose: () => void;
}

// Tab panel component to display the tab content
const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

// Main container component for tabs
const TabContain: React.FC<TabContainProps> = ({
  value,
  handleChange,
  handleClose,
}) => {
  return (
    <Box className="tableDetailsSlide__inner">
      <Box
        className="tableDetailsSlide__header"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        id="test-tab-id" //TODO CHANGE NAME BEFORE PRODUTION
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ maxWidth: 100 }}
        >
          <Tab
            label={<span style={{ maxWidth: 40 }}>Karta</span>}
            sx={{ textTransform: "none" }}
          />
        </Tabs>

        <Button onClick={handleClose} sx={{ maxWidth: 40 }}>
          <CloseOutlinedIcon sx={{ color: "#ff0000" }} />
        </Button>
      </Box>
      <div className="tableDetailsSlide__body">
        <TabPanel value={value} index={0}>
          <LeaseInfo />
        </TabPanel>
      </div>
    </Box>
  );
};

export default TabContain;
