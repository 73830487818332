import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Layout from "./Component/Layout/Layout";
import Register from "./Pages/Register/Register";
import Dashboard from "./Pages/Dashboard/Dashboard";
import SnackBar from "./Component/SnackBar/SnackBar";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ProtectedRoute from "./Routes/Private.Routes";
import { useDispatch } from "react-redux";
import authSlice from "./Redux/Features/Auth-Slice";
import "./app.scss";
import "./Assets/Global-Style/App.css";
import PublicRoute from "./Routes/Public.Route";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import { useMessageCtx } from "./Context/messageContext";
import { Alert } from "./Component/Layout/Alert";

const publicPages = ["/login", "/register", "/register?:email"];

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { messages } = useMessageCtx();
  let { pathname } = useLocation();

  const checkUserToken = async () => {
    const userToken = localStorage.getItem("en-token");
    if (userToken) {
      dispatch(authSlice.actions.updateUser());
    } else {
      localStorage.removeItem("eningo-user");
      localStorage.removeItem("en-token");
      return navigate("/login");
    }
  };
  useEffect(() => {
    const isPublicPage = publicPages.includes(pathname);
    if (!isPublicPage) {
      checkUserToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {messages.length > 0 && (
        <div className="alert-container">
          {messages.map((m) => (
            <Alert key={m._id} {...m} />
          ))}
        </div>
      )}
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register?:email" element={<Register />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route element={<Layout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="overview" element={<>this the overview page </>} />
            <Route path="settings" element={<>this the setting page</>} />
            <Route path="/changePassword" element={<ResetPassword />} />
          </Route>
        </Route>
      </Routes>
      {<SnackBar />}
    </React.Fragment>
  );
}

export default App;
