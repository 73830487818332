import { Button } from "@mui/material";
import { createPortal } from "react-dom";
import { UseDomObserver } from "../../Hooks/useDomObserver";
import { useSessionCtx } from "../../Context/SessionDataContext";
import "./status.scss";

export function StatusToggle(parentSelector: string) {
  const {
    currentWorkOrder,
    updateWoStatus,
    setCurrentWorkOrder,
    setWorkOrderList,
    appConfig,
    addLog,
  } = useSessionCtx();
  const tenantId = appConfig?.tNames.find((t) => t["EningoAB"]) ?? {
    missing: "key",
  };

  const parent = UseDomObserver(
    parentSelector,
    "grid-test-id",
    currentWorkOrder?.tenant_id === Object.values(tenantId)[0] &&
      currentWorkOrder?.status === "done"
  );

  if (!parent) return <></>;

  return createPortal(
    <Button
      id="KUN-toggle-btn"
      onClick={async () => {
        if (currentWorkOrder) {
          const res = await updateWoStatus(
            currentWorkOrder?.WOCaseId,
            currentWorkOrder?.status,
            currentWorkOrder?.db
          );
          if (res.success) {
            setCurrentWorkOrder({
              ...currentWorkOrder,
              status: res.data.Status,
            });
            setWorkOrderList((prev) => {
              const index = prev.findIndex(
                (wo) => wo.WOCaseId === currentWorkOrder.WOCaseId
              );
              if (index !== -1) {
                prev[index].status = res.data.Status;
              }
              return prev;
            });
            const user = JSON.parse(
              localStorage.getItem("eningo-user") ?? "{email:''}"
            ).email;
            await addLog({
              case_id: currentWorkOrder.WOCaseId,
              activity: `Ärende öppnat`,
              user: user,
              dbName: currentWorkOrder.db,
            });
          }
        }
      }}
    >
      Öppna ärende
    </Button>,
    parent
  );
}
