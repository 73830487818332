/* eslint-disable @typescript-eslint/no-unused-vars */
import  { useState } from "react";
import { Button } from "@mui/material";
import ReactMapGl, { Layer, Marker, Source } from "react-map-gl";
import {ArrowDropDownCircle ,CloseOutlined ,CropFree} from "@mui/icons-material"

const map_api = process.env.REACT_APP_MAPBOX_KEY;

const StreetViewMap = ({
  viewport,
  setViewport,
  polygonFromCaseLocation,
  networkCoords,
  WorkOrderList,
  networkImages,
  setOpen,
  mapView,
  setMapView,
  handleOpen,
  open
}: any) => { 
  const [hideMarker, setHideMarker] = useState(true);
  const [mapPopUp, setMapPopUp] = useState(false);

  const handleClose = () => setOpen(false);

  return (
    <div>
      <ReactMapGl
        {...viewport}
        mapboxAccessToken={map_api}
        onMove={(newViewport: any) => {
          setViewport(newViewport.viewState);
          if (newViewport?.zoom < 10) {
            setHideMarker(false);
          }
          if (newViewport?.zoom > 16) {
            setHideMarker(true);
          }
          setMapPopUp(false);
        }}
        // scrollZoom.disable
        mapStyle={"mapbox://styles/mapbox/streets-v12"}
        style={{
          margin: "auto",
          width: "100%",
          maxWidth: "100%",
          height: "450px",
        }}
      >
       
   
          <Button onClick={!open ? handleOpen : handleClose} className={!open ? "mapIconBtn" : "closeBtn"}>
          {!open ? <CropFree /> :  <CloseOutlined /> }
               </Button>
        <div className="mapViewBtn">
                  <Button
                    onClick={() => setMapView(false)}
                    className={
                      !mapView ? "btn activeMap" : "btn"
                    }
                  >
                    Flygfoto
                  </Button>
                  <Button
                    onClick={() => setMapView(true)}
                    className={
                      !mapView
                        ? "btn"
                        : "btn activeMap"
                    }
                  >
                    Karta
                  </Button>
                </div>
        {/* {Math.floor(viewport.zoom) > 17 && ( */}
          <div key={polygonFromCaseLocation}>
            {networkCoords &&
              // eslint-disable-next-line array-callback-return
              Object.keys(networkCoords).map(([key]: any) => {
                if (networkImages[key] && networkCoords[key]) {
                  return (
                    <div key={networkImages[key]}>
                      <Source
                        url={networkImages[key]}
                        coordinates={networkCoords[key]}
                        type={"image"}
                        id={networkImages[key]}
                      >
                        <Layer
                          type={"raster"}
                          paint={{}}
                          id={networkImages[key]}
                          key={networkCoords[key]}
                        />
                      </Source>
                    </div>
                  );
                } else {
                  <div />;
                }
              })}
          </div>
        {/* )} */}

        {polygonFromCaseLocation && (
          <div>
            <Source id="data" type="geojson" data={polygonFromCaseLocation} />
            <Layer
              id={"new"}
              type="fill"
              paint={{
                "fill-color": "red",
                "fill-opacity": 0.3,
              }}
              source={"data"}
            />
          </div>
        )}

        {/* {Math.floor(viewport.zoom) < 18 && */}
         {WorkOrderList?.Center?.WGS84 &&
          WorkOrderList?.Center?.WGS84?.map(
            (data: any, index: number) => {
              return (
                <Marker longitude={data[1]} latitude={data[0]} anchor="left">
                  <div>
                    <ArrowDropDownCircle
                      style={{ fontSize: "40px", color: "#FF8383" }}
                    />
                  </div>
                </Marker>
              );
            }
          )}
      </ReactMapGl>
    </div>
  );
};

export default StreetViewMap;
