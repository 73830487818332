import React, { useState } from "react";
import {
  Box,
  Grid2,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableHead,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  clearOrganizationList,
  clearWorkOrderList,
} from "../../Redux/Features/dashboard-slice";

import { rowsPerPageItem } from "../../Utils/Utils";
import TabContain from "../../Component/Tab/TabContain";
import TableHeader from "../../Component/Table/TableHeader";
import { tableHeader } from "../../Constraints/Tablecontent";
import "./dashboard.scss";
import PrivateDataTable from "../../Component/Table/PrivateDataTable";
import { useSessionCtx } from "../../Context/SessionDataContext";
import Loader from "../../Component/Loader/Loader";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState<string>("20");

  const { setCurrentWorkOrder, workOrderList, isFetching } = useSessionCtx();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(clearWorkOrderList());
    setValue(newValue);
  };

  const handleGet = (item: any) => {
    setOpen(true);
    setCurrentWorkOrder(item);
  };

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    if (page === value) {
      return;
    } else {
      setOpen(false);
      dispatch(clearOrganizationList());
      setPage(value);
    }
  };

  const handleClose = () => {
    setOpen(!open);
    setValue(0);
    dispatch(clearWorkOrderList());
  };

  const statusLegend = [
    {
      status: "Klar",
      color: "#42DB89",
      description: "Ärendet är klarmarkerat",
    },
    {
      status: "Ej klar",
      color: "#FF7043",
      description: "Väntar på utsättning/projektering",
    },
    {
      status: "Avvikelse",
      color: "#FF6E7F",
      description: "Ärendet kunde inte utföras",
    },
    {
      status: "Handläggning krävs",
      color: "#eed202",
      description: "Inväntar tillstånd",
    },
  ];
  return (
    <Box className="dashboardTemplate">
      <Grid2 container spacing={4} id="grid-test-id">
        <Grid2 size={{ xs: 12, md: open ? 8 : 12 }}>
          <Box
            className="tableHeading"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <Typography variant="h4" className="sectionTitle">
              Ärenden
            </Typography>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={rowsPerPage}
              onChange={(event: any) => {
                setOpen(false);
                dispatch(clearOrganizationList());
                setPage(1);
                setRowsPerPage(event.target.value);
              }}
            >
              {Object.entries(rowsPerPageItem).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <div className="accordionBox responsiveTableBlock">
            <Box className="status-legend">
              {statusLegend.map((item) => (
                <Box
                  key={item.status}
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <Box
                    className="status-indicator"
                    sx={{
                      bgcolor: item.color,
                      width: 20,
                      height: 20,
                      borderRadius: "10px", // This creates the rounded shape
                    }}
                  />
                  <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                    {item.status}: {item.description}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Table>
              <TableHead
                style={{
                  background: "whitesmoke",
                }}
              >
                <TableHeader tableHeader={tableHeader} />
              </TableHead>

              <TableBody>
                <PrivateDataTable
                  handleGet={handleGet}
                  page={page}
                  rowsPerPage={parseInt(rowsPerPage)}
                />
              </TableBody>
            </Table>

            {!isFetching && workOrderList.length === 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center", // Centers horizontally
                  alignItems: "center", // Centers vertically
                  height: "100%", // Take up full height available
                  width: "100%", // Take up full width to center content properly
                }}
              >
                <Typography variant="body1" component="p">
                  Inga ärenden hittades
                </Typography>
              </Box>
            )}
            {workOrderList.length === 0 && isFetching && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader />
              </Box>
            )}
          </div>

          {workOrderList.length > 0 && (
            <Pagination
              className="customPagination"
              count={Math.ceil(workOrderList.length / parseInt(rowsPerPage))}
              page={page}
              onChange={changePage}
            />
          )}
        </Grid2>

        {open && (
          <Grid2 size={{ xs: 12, md: 4 }} className="tableDetailsSlide">
            <TabContain
              value={value}
              handleChange={handleChange}
              handleClose={handleClose}
            />
          </Grid2>
        )}
      </Grid2>
    </Box>
  );
};

export default Dashboard;
