import {
  Box,
  TextField,
} from "@mui/material";
import usePasswordToggle from "../../Hooks/usePasswordToggle";

const SecondStep = ({
  registerData,
  onChange,
  organization,
  organizationUser,
  organizationUserName,
}: any) => {
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();

  return (
    <>
      {/* {organization.length > 0 && (
        <Box className="formGroup">
          <InputLabel id="demo-simple-select-label">
            Organisations namn
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            fullWidth
            name="OrganizationNumber"
            value={registerData?.OrganizationNumber}
            onChange={(e) => onChange(e)}
          >
            {organization.map((item: any, i: number) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </Box>
      )} */}
      { organizationUser !== undefined ? (
        <Box className="formGroup">
          <TextField
            type="text"
            name="userName"
            value={organizationUser}
            fullWidth
            label="Namn"
            // onChange={(e) => handleChange(e)}
            disabled={organizationUser}
            focused
          />
        </Box>
      ) : null}

      <Box className="formGroup">
        <TextField
          type={PasswordInputType as string}
          name="password"
          value={registerData?.password}
          fullWidth
          label={"Lösenord"}
          onChange={(e) => onChange(e)}
          focused
        />
        {ToggleIcon}
      </Box>
    </>
  );
};

export default SecondStep;
