
import { Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../Assets/Images/SVG/eningoDarkLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearOrganization } from "../../Redux/Features/Auth-Slice";
import { useNavigate } from "react-router";
import HorizontalLinearStepper from "../../Component/Stepper/Stepper";
import "./register.scss";
import "../Login/login.scss";

const Register = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const isRegister = useSelector((state: any) => state.Auth.isRegister);

  if (isRegister) navigation("/login");

  return (
    <>
      <div className="formTemplate formTemplate">
        <div className="formTemplate__container">
          <div className="formTemplate__logo">
            <Logo />
          </div>

          <div className="formCard">
            <Typography component="h1" variant="h5" className="formCard__title">
              Skapa konto
            </Typography>
            <HorizontalLinearStepper />
            <p className="contentLink" style={{ textAlign: "center" }}>
              Om du redan har ett konto,
              <span
                onClick={() => {
                  dispatch(clearOrganization());
                  navigation("/login");
                }}
                style={{marginLeft: "5px", marginRight: "5px", cursor: "pointer", textAlign: "center", color: "#117A65"}}
              >
                Logga in
              </span>
              istället
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Register;
