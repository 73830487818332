import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AppConfType, PostLogType, WorkOrderType } from "../Types";
import http from "../Service/http.service";

type SessionData = {
  currentWorkOrder: WorkOrderType | undefined;
  setCurrentWorkOrder: Dispatch<SetStateAction<WorkOrderType | undefined>>;
  workOrderList: WorkOrderType[];
  setWorkOrderList: Dispatch<SetStateAction<WorkOrderType[]>>;
  isFetching: boolean;
  setIsFetching: Dispatch<SetStateAction<boolean>>;
  updateWoStatus: (id: string, status: string, dbName: string) => Promise<any>;
  appConfig: AppConfType | undefined;
  addLog: (body: PostLogType) => Promise<any>;
};

export const Ctx = createContext<SessionData | undefined>(undefined);

export const useSessionCtx = () => {
  const ctx = useContext(Ctx);
  if (ctx === undefined) {
    throw new Error("Context is undefined");
  }
  return ctx;
};

export const SessionDataContext = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentWorkOrder, setCurrentWorkOrder] = useState<WorkOrderType>();
  const [workOrderList, setWorkOrderList] = useState<WorkOrderType[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [appConfig, setAppConfig] = useState<AppConfType>();

  const getWorkOrders = useCallback(async () => {
    try {
      const response = await http.get(`/user/getdocument`);

      if (response) {
        const documents = response.data.documentData as Object;
        setWorkOrderList(Object.values(documents).flat());
      }
    } catch (error) {
      setIsFetching(false);
      return error;
    }
    setIsFetching(false);
  }, []);
  const getAppConfig = useCallback(async () => {
    try {
      const response = await http.get(`/user/appconfig`);
      if (response) setAppConfig(response.data);
    } catch (error) {
      return error;
    }
  }, []);
  const updateWoStatus = useCallback(
    async (id: string, status: string, dbName: string) => {
      try {
        const response = await http.put(`/user/workorder/${id}/status`, {
          status,
          dbName,
        });
        if (response) return response.data;
      } catch (error) {
        return error;
      }
    },
    []
  );
  const addLog = useCallback(async (body: PostLogType) => {
    try {
      const res = await http.post<PostLogType>(`/user/log`, body);
      if (res) return res.data;
    } catch (error) {
      return error;
    }
  }, []);

  useEffect(() => {
    setIsFetching(true);

    getWorkOrders();
    getAppConfig();
    return () => {
      setWorkOrderList([]);
      setAppConfig(undefined);
    };
  }, [getWorkOrders, getAppConfig]);

  const value = useMemo(
    () => ({
      currentWorkOrder,
      setCurrentWorkOrder,
      updateWoStatus,
      workOrderList,
      setWorkOrderList,
      isFetching,
      setIsFetching,
      appConfig,
      addLog,
    }),
    [
      currentWorkOrder,
      setCurrentWorkOrder,
      updateWoStatus,
      workOrderList,
      setWorkOrderList,
      isFetching,
      setIsFetching,
      appConfig,
      addLog,
    ]
  );
  return <Ctx.Provider value={value}>{children}</Ctx.Provider>;
};
