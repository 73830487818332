import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hideError } from "../../Redux/Features/Error-Slice";

const SnackBar = () => {
  const isOpen = useSelector((state: any) => state.ErrorReducer.isOpen);
  const error = useSelector((state: any) => state.ErrorReducer.message);
  const status = useSelector((state: any) => state.ErrorReducer.status);
  const dispatch = useDispatch();

  if (!isOpen) {
    return null;
  }

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={8000}
      onClose={() => {
        dispatch(hideError());
      }}
      anchorOrigin={{
         vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Alert
        severity={status === "true" ? "success" : "error"}
        sx={{ width: "100%" }}
        onClose={() => {
          dispatch(hideError());
        }}
      >
        {error}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
