export const tableHeader = [
    {
      field: "WOCaseId",
      title: "Ärendenummer",
      sortable:true
    },
    {
      field: "name",
      title: "Benämning",
      sortable:false
    },
    {
      field: "Tenant",
      title: "Ledningsägare",
      sortable:false
    },
    {
      field: "start_date",
      title: "Startdatum",
      sortable:true,
    },
    {
      field: "Type",
      title: "Ärendetyp",
      sortable:false
    },
    {
      field: "Status",
      title: "Status",
      sortable:true
    },
  ];

export const logDisplayHeader =[
  {
    key:"id",
    title:"id",
  },
  {
    key:"activity",
    title:"activity",
  },
  {
    key:"responsible",
    title:"responsible",
  }
]
  