import { useState } from "react";
import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSessionCtx } from "../../Context/SessionDataContext";
import { toSorted } from "../../Utils/Utils";

const TableHeader = ({ tableHeader }: any) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  const { setWorkOrderList } = useSessionCtx();

  const onSortingChange = (field: string) => {
    const order = field === sortingField && sortingOrder === "asc";
    setWorkOrderList((prev) =>
      [...prev].sort((a, b) => toSorted(a, b, field, order))
    );
    setSortingField(field);
    setSortingOrder(order ? "desc" : "asc");
  };

  return (
    <TableRow>
      {tableHeader.map((item: any, i: any) => {
        return (
          <TableCell key={i} component="th" align={item?.align}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {item.title}
              {item?.sortable && (
                <div className="shortingBtn">
                  <IconButton
                    sx={{
                      padding: "0px",
                    }}
                    onClick={() =>
                      item?.sortable ? onSortingChange(item?.field) : null
                    }
                    disabled={
                      sortingOrder === "asc" && sortingField === item?.field
                    }
                  >
                    <ArrowDropUpIcon />
                  </IconButton>
                  <IconButton
                    sx={{
                      padding: "0px",
                    }}
                    onClick={() =>
                      item?.sortable ? onSortingChange(item?.field) : null
                    }
                    disabled={
                      sortingOrder === "desc" && sortingField === item?.field
                    }
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </div>
              )}
            </Box>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default TableHeader;
