import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import useWindowDimensions from "../../Hooks/useWindowDimensions";

const Layout = () => {
  const [openMenu, setOpenMenu] = useState(true);
  const {width} = useWindowDimensions();
  const [showLogout, setShowLogout] = useState(false);

  useEffect(()=>{
    if(width > 991 ) {
      setShowLogout(false);
    }
    else {
      setShowLogout(true)
      setOpenMenu(true)
    }
  },[width])

  const handleMenu = () => {
    setOpenMenu(!openMenu);
    if(width < 991){
      setShowLogout(!showLogout)
    }
  };
 
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end"
      }}
    >
      <Sidebar openMenu={openMenu} menuBtnEvent={handleMenu}  />
      <Box className="pageWrapper">
        <Header menuEvent={handleMenu} openMenu={openMenu} showLogout={showLogout} />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
