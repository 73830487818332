import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "../Login/login.scss";
import { useDispatch, useSelector } from "react-redux";
import { UserChangePassword } from "../../Redux/Features/Auth-Slice";
import "./resetPassword.scss";
import usePasswordToggle from "../../Hooks/usePasswordToggle";
import { ReactComponent as PasswordIcon } from "../../Assets/Images/SVG/passwordIcon.svg";
const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const users = useSelector((state: any) => state.Auth.user);
  const isFetching = useSelector((state: any) => state.Auth.isFetching);
  const [isPasswordError, setIsPasswordError] = useState("");
  const [isNewPasswordError, setIsNewPasswordError] = useState("");
  const [loginData, setLoginData] = useState({
    email: "",
    oldPassword: "",
    newPassword: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (e.target.name === "oldPassword") setIsPasswordError("");
    if (e.target.name === "newPassword") setIsNewPasswordError("");
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };
  const validation = (loginData: any) => {
    let status = true;
    if (loginData?.email?.length === 0) {
      status = false;
    } else {
      if (loginData?.oldPassword.length === 0) {
        setIsPasswordError("Ange lösenordet");
        status = false;
      }
      if (loginData?.newPassword.length === 0) {
        setIsNewPasswordError("Ange lösenordet");
        status = false;
      }
    }
    return status;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validation(loginData)) {
      dispatch(
        UserChangePassword({
          email: loginData.email,
          oldPassword: loginData.oldPassword,
          newPassword: loginData.newPassword,
        })
      );
    }
  };

  const disabledButton = useMemo(() => {
    if (
      loginData?.oldPassword?.length > 0 &&
      loginData?.newPassword?.length > 0
    )
      return false;
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData]);
  useEffect(() => {
    if (users) {
      setLoginData({
        email: users?.email,
        oldPassword: "",
        newPassword: "",
      });
    }
  }, []);
  return (
    <div className="formTemplate">
      <div className="formTemplate__container">
        <div className="formCard formCard--green">
          <div
            style={{
              maxWidth: "100px",
              margin: "auto",
              marginBottom: "15px",
              width: "100%",
            }}
          >
            <PasswordIcon />
          </div>
          <h3> {t("labels.changePassword")}</h3>
          <form>
            <div className="formGroup">
              <TextField
                type="password"
                fullWidth
                label={t("labels.oldPassword")}
                name="oldPassword"
                value={loginData?.oldPassword}
                onChange={(e) => handleChange(e)}
                focused
              />
              <Typography className="errorText">{isPasswordError}</Typography>
            </div>
            <div className="formGroup">
              <TextField
                type="password"
                fullWidth
                name="newPassword"
                value={loginData?.newPassword}
                onChange={(e) => handleChange(e)}
                label={t("labels.newPassword")}
                focused
              />
              <Typography className="errorText">
                {isNewPasswordError}
              </Typography>
            </div>
            <Box className="formCard__btn">
              <Button
                variant="contained"
                color="primary"
                className="btn"
                onClick={(e) => handleSubmit(e)}
                disabled={isFetching || disabledButton}
              >
                {t("labels.save")}
              </Button>
            </Box>
          </form>
        </div>
        {/* <p className="contentLink" style={{ textAlign: "center" }}>
          {t("labels.allreadyacc")}
          <Link to="/login">{t("labels.login")}</Link>
        </p> */}
      </div>
    </div>
  );
};

export default ResetPassword;
