import { useEffect, useRef, useState } from "react";

/**
 *
 * @param parentSelector Id of the node where the ReactNode should render to as a child
 * @param observerId Id of the node where we observer where the @parentSelector will apper
 * @param readyToRender Boolean to check if the component is ready to render
 * @returns The parentNode so the component can be rendered to it
 */
export function UseDomObserver(
  parentSelector: string,
  observerId: string,
  readyToRender: boolean = true
) {
  const observerRef = useRef<MutationObserver>(null);
  const [parentNode, setParentNode] = useState<HTMLElement | null>();

  useEffect(() => {
    if (!observerRef.current) {
      observerRef.current = new MutationObserver((mutationsList) => {
        const parent = document.getElementById(parentSelector);
        mutationsList.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.contains(parent)) {
              setParentNode(parent);
            }
          });
        });
      });
      const root = document.getElementById(observerId) ?? document.body;
      observerRef.current.observe(root, {
        childList: true,
        subtree: false,
      });
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
    };
  }, [parentSelector, observerId]);

  return readyToRender ? parentNode : null;
}
