import { Box, Typography } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./header.scss";
import { useEffect } from "react";
interface ChildProps {
  menuEvent: () => void;
  openMenu: boolean;
  showLogout: boolean;
}

const Header: React.FC<ChildProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLogin = useSelector((state: any) => state.Auth.isLogin);
  const profile = location.pathname;

  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, [isLogin, navigate]);

  return (
    <Box
      className="mainHeader"
      p={"20px 25px"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderBottom={"1px solid #C4C4C4"}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={"20px"}
        className="mainHeader__heading"
      >
        <Box
          onClick={() => props.menuEvent()}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          style={{ cursor: "pointer" }}
        >
          <Menu />
        </Box>
        {profile === "/profile" && (
          <Typography
            style={{ fontWeight: 700 }}
            variant="h4"
            className="sectionTitle"
          >
            Profil
          </Typography>
        )}
      </Box>
    </Box>
  );
};
export default Header;
