import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableHead,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { ThreeDots } from 'react-loader-spinner';
import {
  clearOrganizationList,
  getOrganizationList,
} from "../../Redux/Features/dashboard-slice";
import { clearWorkOrderList } from "../../Redux/Features/dashboard-slice";
import {
  rowsPerPageItem,
} from "../../Utils/Utils";
import TabContain from "../../Component/Tab/TabContain";
import { RootState } from "../../Redux/Store";
import TableHeader from "../../Component/Table/TableHeader";
import { tableHeader } from "../../Constraints/Tablecontent";
import "./dashboard.scss";
import PublicDataTable from "../../Component/Table/PublicDataTable";
import PrivateDataTable from "../../Component/Table/PrivateDataTable";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [isCaseId, setIsCaseId] = useState<number | null>(null);
  const [selectCaseId, setSelectCaseId] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState<string>("20");
  const [filterSelect, setFilterSelect] = useState("all");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const getAllOrgs = useSelector(
    (state: RootState) => state.DashReducer.organizationInq
  );
  const getAllOrganization = Object.values(getAllOrgs).flat();
  const isFetching = useSelector(
    (state: RootState) => state.DashReducer.isFetching
  );

  const totalPage = useSelector(
    (state: RootState) => state?.DashReducer?.totalPage
  );

  const OrganizationNumber = useSelector(
    (state: RootState) => state?.DashReducer?.OrganizationNumber
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(clearWorkOrderList());
    setValue(newValue);
  };

  const handleGet = (item: any) => {
    setOpen(true);
    setSelectCaseId(item);

  };

  // Function to handle expanding/collapsing rows
  const handleRowClick = (item: any) => {
    setIsCaseId((prevCaseId) =>
      prevCaseId === item?.WOCaseId ? null : item?.WOCaseId
    );
  };

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    if (page === value) {
      return;
    } else {
      setOpen(false);
      dispatch(clearOrganizationList());
      setPage(value);
    }
  };

  const handleClose = () => {
    setOpen(!open);
    setValue(0);
    dispatch(clearWorkOrderList());
  };

  const statusLegend = [
    { status: 'Klar', color: '#42DB89', description: 'Ärendet är klarmarkerat' },
    { status: 'Ej klar', color: '#FF7043', description: 'Väntar på utsättning/projektering' },
    { status: 'Avvikelse', color: '#FF6E7F', description: 'Ärendet kunde inte utföras' },
  ];

  useEffect(() => {
    if (Object.keys(getAllOrganization).length > 0)
      dispatch(clearOrganizationList());

    dispatch(
      getOrganizationList({
        casetype: filterSelect,
        limit: rowsPerPage,
        page: page,
        field: sorting?.field,
        sort: sorting?.order,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelect, rowsPerPage, page, sorting]);



  return (
    <>
      <Box className="dashboardTemplate">
        <Grid container spacing={4}>
          <Grid item xs={12} md={open ? 8 : 12}>
            <Box className="tableHeading" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '20px', paddingRight: '20px' }}>
              <Typography variant="h4" className="sectionTitle">
                Ärenden
              </Typography>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={rowsPerPage}
                onChange={(event: any) => {
                  setOpen(false);
                  dispatch(clearOrganizationList());
                  setFilterSelect("all");
                  setPage(1);
                  setRowsPerPage(event.target.value);
                }}
              >
                {Object.entries(rowsPerPageItem).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <div className="accordionBox responsiveTableBlock">
              <Box className="status-legend" >
                {statusLegend.map((item) => (
                  <Box key={item.status} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box className="status-indicator" sx={{
                      bgcolor: item.color,
                      width: 20,
                      height: 20,
                      borderRadius: '10px' // This creates the rounded shape
                    }} />
                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                      {item.status}: {item.description}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Table>
                <TableHead
                  style={{
                    background: "whitesmoke",
                  }}
                >
                  <TableHeader
                    tableHeader={tableHeader}
                    onSorting={(field: string, order: string) =>
                      setSorting({ field, order })
                    }
                  />
                </TableHead>
                <TableBody>
                  <PrivateDataTable
                    getAllOrganization={getAllOrganization}
                    isCaseId={isCaseId}
                    handleGet={handleGet}
                  />
                </TableBody>
              </Table>

              {!isFetching && Object.keys(getAllOrganization).length === 0 && (
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center', // Centers horizontally
                  alignItems: 'center', // Centers vertically
                  height: '100%', // Take up full height available
                  width: '100%' // Take up full width to center content properly
                }}>
                  <Typography variant="body1" component="p">
                    Inga ärenden hittades
                  </Typography>
                </Box>
              )}
              {isFetching && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                </Box>
              )}
            </div>

            {Object.keys(getAllOrganization).length > 0 && (
              <Pagination
                className="customPagination"
                count={totalPage}
                page={page}
                onChange={changePage}
              />
            )}
          </Grid>

          {open && (
            <Grid item xs={12} md={4} className="tableDetailsSlide">
              <TabContain
                value={value}
                handleChange={handleChange}
                handleClose={handleClose}
                selectCaseId={selectCaseId}
                WorkOrderList={selectCaseId}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;