// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registerStepper .MuiStepper-root {
  margin-bottom: 32px;
}
.registerStepper .MuiStepper-root .MuiStepLabel-root .MuiSvgIcon-root {
  color: #a6cfd3;
}
.registerStepper .MuiStepper-root .MuiStepLabel-root .MuiSvgIcon-root.Mui-active, .registerStepper .MuiStepper-root .MuiStepLabel-root .MuiSvgIcon-root.Mui-completed {
  color: #42db89;
}
.registerStepper .MuiStepper-root .MuiStepLabel-label {
  margin-top: 6px;
  font-size: 12px;
  line-height: 18px;
  color: #717070;
}
.registerStepper .MuiStepper-root .MuiStepConnector-line {
  border-color: #a6cfd3;
  border-width: 2px;
}
.registerStepper .MuiStepper-root .Mui-active + .MuiStepLabel-labelContainer .MuiStepLabel-label,
.registerStepper .MuiStepper-root .Mui-completed + .MuiStepLabel-labelContainer .MuiStepLabel-label {
  color: #42db89;
}
.registerStepper .MuiStepper-root .Mui-active .MuiStepConnector-line {
  border-color: #42db89;
}
.registerStepper__btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.registerStepper__btns button {
  border-radius: 8px;
  min-width: 110px;
}
.registerStepper__btns button:disabled {
  opacity: 40%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Register/register.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAEM;EACE,cAAA;AAAR;AACQ;EAEE,cAAA;AAAV;AAII;EACE,eAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFN;AAII;EACE,qBAAA;EACA,iBAAA;AAFN;AAII;;EAEE,cAAA;AAFN;AAKM;EACE,qBAAA;AAHR;AAOE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;AALJ;AAMI;EACE,kBAAA;EACA,gBAAA;AAJN;AAKM;EACE,YAAA;AAHR","sourcesContent":[".registerStepper {\n  .MuiStepper-root {\n    margin-bottom: 32px;\n    .MuiStepLabel-root {\n      .MuiSvgIcon-root {\n        color: #a6cfd3;\n        &.Mui-active,\n        &.Mui-completed {\n          color: #42db89;\n        }\n      }\n    }\n    .MuiStepLabel-label {\n      margin-top: 6px;\n      font-size: 12px;\n      line-height: 18px;\n      color: #717070;\n    }\n    .MuiStepConnector-line {\n      border-color: #a6cfd3;\n      border-width: 2px;\n    }\n    .Mui-active + .MuiStepLabel-labelContainer .MuiStepLabel-label,\n    .Mui-completed + .MuiStepLabel-labelContainer .MuiStepLabel-label {\n      color: #42db89;\n    }\n    .Mui-active {\n      .MuiStepConnector-line {\n        border-color: #42db89;\n      }\n    }\n  }\n  &__btns {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 10px;\n    button {\n      border-radius: 8px;\n      min-width: 110px;\n      &:disabled {\n        opacity: 40%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
