import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment";
import Map from "../Map/Map";
import StreetViewMap from "../Map/StreetViewMap";
import MapModel from "../Map/MapModel";
import "./leaseinfo.scss";
import { useSessionCtx } from "../../Context/SessionDataContext";

const LeaseInfo = () => {
  const [viewport, setViewport] = useState<any>({
    latitude: 59.334591,
    longitude: 18.06324,
    zoom: 8,
    width: "100%",
    height: "45vh",
    margin: "auto",
  });

  const [polygonFromCaseLocation, setPolygonFromCaseLocation] = useState<any>();
  const { currentWorkOrder } = useSessionCtx();
  const [networkCoords, setNetworkCoords] = useState<number[][][]>([]);
  const [networkImages, setNetworkImages] = useState<Array<string>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [mapView, setMapView] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const newViewport = {
      ...viewport,
      latitude:
        currentWorkOrder?.CAddress?.[0]?.[0] === undefined
          ? 59.334591
          : currentWorkOrder?.CAddress?.[0]?.[0],
      longitude:
        currentWorkOrder?.CAddress?.[0]?.[1] === undefined
          ? 18.06324
          : currentWorkOrder?.CAddress?.[0]?.[1],

      zoom: 17,
    };
    setViewport(newViewport);

    setPolygonFromCaseLocation(currentWorkOrder?.Geometry4326);
    if (currentWorkOrder?.network_coords) {
      setNetworkCoords(currentWorkOrder?.network_coords);
    }
    if (currentWorkOrder?.Cable_Data) {
      setNetworkImages(currentWorkOrder?.Cable_Data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkOrder]);

  return (
    <>
      <Box>
        <div className="tabmain">
          <span className="infoLabel"> # {currentWorkOrder?.WOCaseId}</span>
        </div>
        <div className="lesserInfo">
          <span className="infoLabel">Plats</span>
          <span className="infoValue">
            {
              currentWorkOrder?.Address
                ? [currentWorkOrder.Address?.StreetNameAndNumber]
                    .filter(Boolean)
                    .join(", ")
                : "-" // Default text or handling if address parts are missing
            }
          </span>
        </div>
        <div className="lesserInfo">
          <span className="infoLabel">Startdatum</span>
          <span className="infoValue">
            {moment(currentWorkOrder?.start_date).format("MMM DD YYYY ")}
          </span>
        </div>
        <div className="lesserInfo">
          <span className="infoLabel">Antal område</span>
          <span className="infoValue">{currentWorkOrder?.number_of_sites}</span>
        </div>

        <Box className="mapViewBox">
          <>
            {!mapView ? (
              <Map
                viewport={viewport}
                setViewport={setViewport}
                polygonFromCaseLocation={polygonFromCaseLocation}
                networkCoords={networkCoords}
                WorkOrderList={currentWorkOrder}
                networkImages={networkImages}
                setOpen={setOpen}
                mapView={mapView}
                setMapView={setMapView}
                handleOpen={handleOpen}
                open={open}
              />
            ) : (
              <StreetViewMap
                viewport={viewport}
                setViewport={setViewport}
                polygonFromCaseLocation={polygonFromCaseLocation}
                networkCoords={networkCoords}
                WorkOrderList={currentWorkOrder}
                networkImages={networkImages}
                setOpen={setOpen}
                mapView={mapView}
                setMapView={setMapView}
                handleOpen={handleOpen}
                open={open}
              />
            )}
          </>
        </Box>
      </Box>

      {open && (
        <MapModel
          viewport={viewport}
          setViewport={setViewport}
          polygonFromCaseLocation={polygonFromCaseLocation}
          networkCoords={networkCoords}
          WorkOrderList={currentWorkOrder}
          networkImages={networkImages}
          setOpen={setOpen}
          handleClose={handleClose}
          open={open}
        />
      )}
    </>
  );
};

export default LeaseInfo;
