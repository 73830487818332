import { Outlet, Navigate } from "react-router-dom";
import { SessionDataContext } from "../Context/SessionDataContext";

const PrivateRoute = ({ user }: any) => {
  const arrayString = localStorage.getItem("eningo-user");
  let userExist: any = {};
  userExist =
    arrayString && arrayString !== "undefined" ? JSON.parse(arrayString) : "";

  return userExist ? (
    <SessionDataContext>
      <Outlet />
    </SessionDataContext>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
