import axios from "axios";
const mAxios = axios.create({
  baseURL: process.env.REACT_APP_MESSAGE_SERVER,
  headers: { Accept: "application/json" },
});

// Add a request interceptor
mAxios.interceptors.request.use(
  (config: any) => {
    const token: any = process.env.REACT_APP_MESSAGE_API_KEY;
    if (token) {
      config.headers["api-key"] = `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const messageService = {
  request: mAxios,
  get: mAxios.get,
  post: mAxios.post,
};

export default messageService;
