import { useState } from "react";
import StreetViewMap from "./StreetViewMap";
import Map from "./Map";
import { Box, Modal } from "@mui/material";

export default function MapModel({
  viewport,
  setViewport,
  polygonFromCaseLocation,
  networkCoords,
  WorkOrderList,
  networkImages,
  setOpen,
  handleClose,
  open,
}: any) {
  const [mapView, setMapView] = useState(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="mapModel"
    >
      <Box className="mapModel__card">
        <>
          {!mapView ? (
            <Map
              viewport={viewport}
              setViewport={setViewport}
              polygonFromCaseLocation={polygonFromCaseLocation}
              networkCoords={networkCoords}
              WorkOrderList={WorkOrderList}
              networkImages={networkImages}
              setOpen={setOpen}
              mapView={mapView}
              setMapView={setMapView}
              open={open}
            />
          ) : (
            <StreetViewMap
              viewport={viewport}
              setViewport={setViewport}
              polygonFromCaseLocation={polygonFromCaseLocation}
              networkCoords={networkCoords}
              WorkOrderList={WorkOrderList}
              networkImages={networkImages}
              setOpen={setOpen}
              mapView={mapView}
              setMapView={setMapView}
              open={open}
            />
          )}
        </>
      </Box>
    </Modal>
  );
}
