import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Logo } from "../../Assets/Images/SVG/eningoDarkLogo.svg";
import { SignIn, clearOrganization } from "../../Redux/Features/Auth-Slice";
import usePasswordToggle from "../../Hooks/usePasswordToggle";
import { checkIsEmail } from "../../Utils/Utils";

const Login = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: any) => state.Auth.isLogin);
  const isFetching = useSelector((state: any) => state.Auth.isFetching);
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [isEmailError, setIsEmailError] = useState("");
  const [isPasswordError, setIsPasswordError] = useState("");

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    organizationNumber: "",
  });

  const search = window.location.search;
  const searchVal = new URLSearchParams(search);

  let searchEmail: any = searchVal.get("email");
  let searchPassword: any = searchVal.get("password");

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (e.target.name === "email") setIsEmailError("");
    if (e.target.name === "password") setIsPasswordError("");
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const validation = (loginData: any) => {
    let status = true;
    if (loginData?.email?.length === 0) {
      setIsEmailError("Ange e-postadress ");
      status = false;
    } else {
      if (!checkIsEmail(loginData?.email)) {
        setIsEmailError("Fel e-postadress ");
        status = false;
      }
      if (loginData?.password.length === 0) {
        setIsPasswordError("Ange lösenordet");
        status = false;
      }
    }
    return status;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validation(loginData)) {
      dispatch(
        SignIn({
          email: loginData.email,
          password: loginData.password,
        })
      );
    }
  };

  const disabledButton = useMemo(() => {
    if (loginData?.email.length > 0 && loginData?.password?.length > 0)
      return false;
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData]);

  useEffect(() => {
    dispatch(clearOrganization());
    if (searchEmail !== null) {
      setLoginData({
        ...loginData,
        email: searchEmail,
        password: searchPassword,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn) navigation("/");

  return (
    <>
      <div className="formTemplate formTemplate">
        <div className="formTemplate__container">
          <div className="formTemplate__logo">
            <Logo />
          </div>
          <div className="formCard formCard--green">
            <Typography component="h1" variant="h5" className="formCard__title">
              Logga in
            </Typography>
            <form>
              <Box className="formGroup">
                <TextField
                  type="email"
                  fullWidth
                  name="email"
                  value={loginData?.email}
                  label={"Användarnamn"}
                  onChange={(e) => handleChange(e)}
                  focused
                />
                <Typography className="errorText">{isEmailError}</Typography>
              </Box>
              <Box className="formGroup">
                <TextField
                  type={PasswordInputType as string}
                  name="password"
                  value={loginData?.password}
                  fullWidth
                  label={"Lösenord"}
                  onChange={(e) => handleChange(e)}
                  focused
                />
                {ToggleIcon}
                <Typography className="errorText">{isPasswordError}</Typography>
              </Box>
              <Box className="formCard__btn">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="btn"
                  onClick={(e) => handleSubmit(e)}
                  disabled={isFetching || disabledButton}
                >
                  {isFetching ? <CircularProgress size={23} /> : "Logga in"}
                </Button>
              </Box>
            </form>
            <div className="contentLink a" style={{ textAlign: "center" }}>
              <span
                onClick={() => {
                  dispatch(clearOrganization());
                  navigation("/register");
                }}
                style={{ cursor: "pointer", margin: "0 5px", color: "#117A65" }} // Add styles as needed
              >
                Skapa konto
              </span>
              |
              <span
                onClick={() => {
                  dispatch(clearOrganization());
                  navigation("/forgotPassword");
                }}
                style={{ cursor: "pointer", margin: "0 5px", color: "#117A65" }} // Add styles as needed
              >
                Glömt lösenord?
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
